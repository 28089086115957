<template>
    <div v-if="userDataStore.isAdmin" class="event-save-button">
        <i class="fa-solid fa-circle-check" v-on:click="doUpdateEvent()"></i>
    </div>
</template>

<script>
import { useUserDataStore } from '@/stores/UserData.js';
import { storeToRefs } from 'pinia';
import { put } from '@/modules/AuthorisedRequest.js';

export default {
    name: 'EventUpdateButton',
    props: ['eventId', 'eventName','eventDescription','eventDate','eventTime','eventRouteId'],
    data() {
        return {
            userDataStore: useUserDataStore(),
            userData: storeToRefs(useUserDataStore())
        }
    },
    setup() {
        const updateEvent = (eventId, eventRouteId, eventDate, eventTime, eventName, eventDescription) => {
            console.log(process.env.VUE_APP_ROUTE_SERVER_URI + '/events/' + eventId)
            put( process.env.VUE_APP_ROUTE_SERVER_URI + '/events/' + eventId, {
                "id": eventId,
                "routeId": eventRouteId,
                "date": eventDate,
                "time": eventTime,
                "name": eventName,
                "description": eventDescription
            })
        }        
        return {
            updateEvent
        }
    },
    methods: {
        doUpdateEvent() {
            this.updateEvent(this.eventId, this.eventRouteId, this.eventDate, this.eventTime, this.eventName, this.eventDescription)
        }
    }
}
</script>

<style scoped>
.event-save-button {
    font-size: 1.5rem;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}
</style>