<template>
    <full-page title="Event Admin">
        <div v-if="events" class="event-list">
            <EventDetails  v-for="event in events" :key="event.id" :event="event"/>
        </div>
    </full-page>
</template>

<script>
import EventDetails from '@/components/event/EventDetails';
import FullPage from '@/components/page/FullPage';
import { get } from '@/modules/AuthorisedRequest.js';

export default {
    name: 'EventAdminPage',
    components: {
        EventDetails,
        FullPage,
    },
    data() {
        return {
            events: null
        }
    },
    created() {
      console.log("Querying " + process.env.VUE_APP_ROUTE_SERVER_URI + "/events/");
      const eventsUrl = process.env.VUE_APP_ROUTE_SERVER_URI + '/events/';
      get(eventsUrl)
        .then((response) => { this.events = response.data; });
    },
};
</script>

<style scoped>

</style>