<template>
    <div v-if="userDataStore.isAdmin" class="event-delete-button">
        <i class="fa-solid fa-trash" v-on:click="confirmDeleteEvent()"></i>
    </div>
</template>

<script>
import { useUserDataStore } from '@/stores/UserData.js';
import { storeToRefs } from 'pinia';
import { sendDelete } from '@/modules/AuthorisedRequest.js';
import { useRouter } from 'vue-router'

export default {
    name: 'EventDeleteButton',
    props: ['event'],
    data() {
        return {
            userDataStore: useUserDataStore(),
            userData: storeToRefs(useUserDataStore())
        }
    },
    setup() {
        const router = useRouter();
        const refresh = () => {router.go(0)};

        const deleteEvent = (eventID) => {
            console.log("Deleting event " + eventID)
            sendDelete( process.env.VUE_APP_ROUTE_SERVER_URI + '/events/' + eventID)
            .then(refresh)
            ;                
        }        
        return {
            deleteEvent
        }
    },
    methods: {
        confirmDeleteEvent() {
            if (confirm("Delete event " + this.event.name + "?")) {
                this.deleteEvent(this.event.id)
            }
        },
    }
}
</script>

<style scoped>
.event-delete-button {
    font-size: 1.5rem;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}
</style>