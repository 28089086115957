<template>
    <div v-if="userDataStore.isAdmin" class="route-schedule-button">
        <i class="fa-solid fa-calendar-days" v-on:click="doScheduleRoute()"></i>
    </div>
</template>

<script>
import { useUserDataStore } from '@/stores/UserData.js';
import { storeToRefs } from 'pinia';
import { post } from '@/modules/AuthorisedRequest.js';
import { useRouter } from 'vue-router'

export default {
    name: 'RouteScheduleButton',
    props: ['routeId', 'routeName','routeDescription'],
    data() {
        return {
            userDataStore: useUserDataStore(),
            userData: storeToRefs(useUserDataStore())
        }
    },
    setup() {
        const router = useRouter();
        const scheduleRoute = (routeId, routeName, routeDescription, eventDate, eventTime) => {
            console.log(process.env.VUE_APP_ROUTE_SERVER_URI + '/events/')
            post( process.env.VUE_APP_ROUTE_SERVER_URI + '/events/', {
                "routeId": routeId,
                "name": routeName,
                "description": routeDescription,
                "date": eventDate,
                "time": eventTime
            }).then(router.replace({name: 'event-admin'}));                
        }        
        return {
            scheduleRoute
        }
    },
    methods: {
        doScheduleRoute() {
            // TODO: popup a entry box for datetime; parse and pass in as date and time
            var dateString = prompt("Event date (yyyy-mm-dd)")
            var time = "07:00:00"
            this.scheduleRoute(this.routeId, this.routeName, this.routeDescription, dateString, time)
        }
    }
}
</script>

<style scoped>
.route-schedule-button {
    font-size: 1.5rem;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}
</style>