<template>
  <div class="event-details">
    <router-link :to="`/route/${event.routeId}`">
      <i class="fa-solid fa-eye"></i>
    </router-link>
    <div class="event-date" contenteditable @input="updateDate($event)">
      {{ event.date }}
    </div>
    <div class="event-time" contenteditable @input="updateTime($event)">
      {{ event.time }}
    </div>
    <div class="event-name" contenteditable @input="updateName($event)">
      {{ event.name }}
    </div>
    <div class="event-description" contenteditable @input="updateDescription($event)">
      {{ event.description }}
    </div>
    <div class="event-route" contenteditable @input="updateRouteId($event)">
      {{ event.routeId }}
    </div>
    <EventDeleteButton v-if="event" :event="event"/>
    <EventUpdateButton v-if="event" :eventId="eventId" :eventRouteId="eventRouteId" :eventDate="eventDate" :eventTime="eventTime" :eventName="eventName" :eventDescription="eventDescription"/>
  </div>
</template>

<script>
import EventDeleteButton from '@/components/event/EventDeleteButton';
import EventUpdateButton from '@/components/event/EventUpdateButton';

export default {
    name: 'EventDetails',
    props: ['event'],
    components: {
      EventDeleteButton,
      EventUpdateButton
    },
    data() {
      return {
        eventId: this.event.id,
        eventDate: this.event.date,
        eventTime: this.event.time,
        eventName: this.event.name,
        eventDescription : this.event.description,
        eventRouteId: this.event.routeId
      }
    },
    methods: {
      updateDate(event) {
        this.eventDate = event.target.innerText
      },
      updateTime(event) {
        this.eventTime = event.target.innerText
      },
      updateName(event) {
        this.eventName = event.target.innerText
      },
      updateDescription(event) {
        this.eventDescription = event.target.innerText
      },
      updateRouteId(event) {
        this.eventRouteId = event.target.innerText
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-details {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px black;
  font-size: 1.5rem;
}

.event-date,
.event-time,
.event-name,
.event-route {
  text-align: left;
  margin: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: bold;
}

.event-description {
  margin: 1rem;
  word-wrap: normal;
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;
}

.event-downloads {
  width: 8rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.event-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 150px;
  font-size: 1.2rem;
}

h2 {
  margin: 40px 0 0;
}
a {
  color: #157047;
}
</style>